<template>
  <v-container
    fluid
    class="ao-list"
  >
    <v-card>
      <v-card-title>
        <v-spacer />
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-database-search"
          label="Search"
          single-line
          hide-details
          prepend-icon="mdi-filter"
          clearable
          :disabled="loading"
          @click:prepend="openAOFilter"
        />
      </v-card-title>

      <v-data-table
        v-model="selected"
        show-select
        :headers="aoListHeaders"
        :items="aoListItems"
        :loading="loading"
        item-key="id"
        :items-per-page-items="rowsPerPageOptions"
        class="elevation-1"
        :search="search"
        @click:row="rowClicked"
      >
        <template #[`item.datetime`]="{ item }">
          <td>{{ item.datetime.format('[On] ll [at] LT') }}</td>
        </template>
      </v-data-table>
    </v-card>

    <!-- The MATLAB code dialog box -->
    <v-dialog
      v-model="matlabCodeDialog"
    >
      <v-card
        tile
        class="matlab-code-dialog"
      >
        <v-app-bar
          flat
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="matlabCodeDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Matlab Code</v-toolbar-title>
          <!--<v-spacer/>-->
          <!--<v-toolbar-items>-->
          <!-- If any other shiz is desired in the toolbar, put it here. -->
          <!--</v-toolbar-items>-->
        </v-app-bar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-form
                ref="matlabCodeForm"
                v-model="matlabFormValid"
                lazy-validation
              >
                <template v-for="field in matlabCodeFormData">
                  <FormField
                    :key="field.key"
                    :ref="field.type"
                    :entry="field"
                    @onDynamicUpdate="getMatlabCode"
                  />
                </template>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- The AO filtering dialog box -->
    <v-dialog
      v-model="aoFilterDialog"
    >
      <v-card
        tile
        class="ao-filter-dialog"
      >
        <v-app-bar
          flat
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="aoFilterDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Current AO Filter Settings</v-toolbar-title>
          <!--<v-spacer/>-->
          <!--<v-toolbar-items>-->
          <!--</v-toolbar-items>-->
        </v-app-bar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-form
                ref="aoFilterForm"
                v-model="aoFilterFormValid"
                lazy-validation
              >
                <template v-for="field in aoFilterFormData">
                  <FormField
                    :key="field.key"
                    :ref="field.type"
                    :entry="field"
                  />
                </template>
              </v-form>
            </v-col>

            <v-col
              cols="12"
              class="pt-2"
            >
              <v-btn
                rounded
                color="primary"
                :disabled="loading"
                @click.stop="setAOFilter"
              >
                Set
              </v-btn>
              <v-btn
                rounded
                color="grey lighten-1"
                :disabled="loading"
                @click.stop="clearAOFilter"
              >
                Clear
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- The MATLAB code dialog box opening FAB button -->
    <v-fab-transition>
      <v-btn
        v-show="selected.length"
        mode="out-in"
        dark
        fab
        bottom
        right
        fixed
        class="fab-bottom-right"
        color="accent"
        @click="getMatlabCode"
      >
        <v-icon>mdi-download</v-icon>
        <span class="fab-text">
          Get MATLAB Code
        </span>
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>

<script>
import { EventBus } from '../scripts/event_bus'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'

export default {
  name: 'AOList',
  components: {
    FormField: () => import('../components/FormField')
  },
  data () {
    return {
      localLoading: false,
      aoListHeaders: [
        { text: 'Name', align: 'left', value: 'name' },
        { text: 'File ID', value: 'fileid' },
        { text: 'Added', value: 'datetime' },
        { text: 'Filename', value: 'filename' },
        { text: 'LED', value: 'led' }
      ],
      rowsPerPageOptions: [
        10, 20, 30, 50, 100,
        { text: 'All', value: -1 }
      ],
      pagination: {
        sortBy: 'name'
      },
      selected: [],
      search: '',
      matlabCodeDialog: false,
      matlabFormValid: true,
      matlabCodeFormData: [],
      aoFilterDialog: false,
      aoFilterFormValid: true,
      aoFilterFormData: []
    }
  },
  computed: {
    ...mapGetters({
      globalLoading: 'loading',
      aoListItems: 'aoList'
    }),
    loading: function () {
      return this.globalLoading || this.localLoading
    }
  },
  mounted () {
    this.refreshAOList()
    this.aoFilterFormData = []
    EventBus.$on('refresh', this.refreshAOList)
  },
  beforeDestroy () {
    EventBus.$off('refresh', this.refreshAOList)
  },
  methods: {
    ...mapActions({
      refreshAOList: 'updateAOList'
    }),
    ...mapMutations({
      setAOFilters: 'setAOListFilters'
    }),
    rowClicked: function (_, rowClickEvent) {
      rowClickEvent.select(!rowClickEvent.isSelected)
    },
    openAOFilter: function () {
      if (this.aoFilterFormData.length === 0) {
        this.localLoading = true

        this.$root.$data.si.getAOFilterForm(resp => {
          this.aoFilterFormData = resp.fields

          // Because Simon seems to think that the from-to date field should be 1969 to... 1969,
          // I will override this to something useful...
          this.aoFilterFormData.forEach(filterField => {
            if (filterField.key === 'time_start') {
              // Preset this value to something more useful...
              filterField.value = 1517529600
              filterField.date = moment.unix(filterField.value).format('YYYY-MM-DD')
              filterField.time = moment.unix(filterField.value).format('HH:mm')
            } else if (filterField.key === 'time_stop') {
              // Set the time stop to right now.
              filterField.value = moment().unix()
              filterField.date = moment.unix(filterField.value).format('YYYY-MM-DD')
              filterField.time = moment.unix(filterField.value).format('HH:mm')
            }
          })

          // console.log(this.aoFilterFormData)

          this.localLoading = false
          this.aoFilterDialog = true
        })
      } else {
        this.aoFilterDialog = true
      }
    },
    setAOFilter: function () {
      const aoFilters = []

      this.aoFilterFormData.forEach(field => {
        aoFilters.push({ key: field.key, value: field.value })
      })

      this.setAOFilters(aoFilters)

      this.aoFilterDialog = false
      this.refreshAOList()
    },
    clearAOFilter: function () {
      this.setAOFilters([])
      this.aoFilterFormData = []

      this.aoFilterDialog = false
      this.refreshAOList()
    },
    getMatlabCode: function () {
      const aoIds = this.selected.map(ao => ao.id)

      if (!this.loading) {
        this.localLoading = true

        const postArray = [{ key: 'aos', value: aoIds }]

        // Add in any extra fields from the dialog
        this.matlabCodeFormData.forEach(codeFormEntry => {
          postArray.push(codeFormEntry)
        })

        // Clear the array before... assigning to the array?
        // Doesn't work withour this. Why does this work, I have no idea...!?
        this.matlabCodeFormData = []

        this.$root.$data.si.getAOMatlabCode(postArray, response => {
          // console.log(response)

          this.matlabCodeFormData = response.fields

          this.matlabCodeDialog = true
          this.localLoading = false
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
